<template>
  <Transition :name="transformName">
    <aside
      class="ui-w-[95%] sm:ui-w-[448px] ui-fixed ui-top-none ui-h-dvh ui-flex ui-flex-col ui-bg-white ui-z-[60]"
      :class="positionClass"
      v-show="isOpen"
    >
      <header
        v-if="$slots.header"
        class="ui-py-sm ui-px-sm ui-grid ui-border-b-sm ui-border-grey-default"
      >
        <slot name="header" />
      </header>

      <div
        class="ui-flex-1"
        :class="[
          slotClass,
          hideScroll
            ? 'ui-overflow-y-scroll md:ui-overflow-hidden'
            : 'ui-overflow-y-scroll md:ui-overflow-y-scroll',
        ]"
      >
        <slot />
      </div>

      <footer
        v-if="$slots.footer"
        class="ui-sticky ui-bg-white ui-bottom-none ui-w-full ui-px-sm ui-py-sm ui-shadow-top-xs ui-z-[70]"
      >
        <slot name="footer" />
      </footer>
    </aside>
  </Transition>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { DrawerProps } from "./types";

const props = defineProps<DrawerProps>();

const positionClass = computed(() =>
  props.position === "right" ? "ui-right-none" : "ui-left-none",
);
const transformName = computed(() =>
  props.position === "right" ? "slide-right" : "slide-left",
);
</script>

<style>
.open {
  transform: translateX(200px);
}

.slide-left-enter-active {
  transition: all 150ms ease-in-out;
}

.slide-left-leave-active {
  transition: all 150ms ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-400px);
}

.slide-right-enter-active {
  transition: all 150ms ease-in-out;
}

.slide-right-leave-active {
  transition: all 150ms ease-in-out;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(400px);
}
</style>
